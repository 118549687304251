import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu-hasdropdown"><a  href={process.env.PUBLIC_URL + '/'}>Home</a>                  
                    </li>
                    <li className="menu-hasdropdown"><a>services</a>
                        <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/it_consultancy'}>IT Consultancy</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/digital_services'}>Digital Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/advisory_services'}>Advisory Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/data_structuring'}>Data Structuring</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/experience_design'}>Experience Design</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/content_engineering'}>Content Engineering</a></li>
                        </ul>
                    </li> 
                    <li className="menu-hasdropdown"><a  href={process.env.PUBLIC_URL + '/aboutus_01'}>Careers</a>
                    </li>                   
                    <li className="menu-hasdropdown"><a  href={process.env.PUBLIC_URL + '/aboutus_01'}>About Us</a>
                    </li>
                    <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/Contact_01'}>Contact Us</a>
                    </li>
                     </ul>
            </nav>
    
            )
    }
}